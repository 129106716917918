import Vue from "vue";
import bncBizUiFooter from "bnc-ui-footer";
import bncMobileMgr from "bnc-mobile-mgr";

new Vue({
    el: "#vueBox",
    data: {
        isleft: true,
        activeTitle: "最新动态",
        currentProjectEnv: "bnc",
        textContentData: {
            bgText: {},
            cPicArr: {},
            industryData: {},
            navData: {},
            talkData: {},
        },
    },
    created() {
        this.currentProjectEnv = urls.currentProjectEnv;
        switch (this.currentProjectEnv) {
            case "anxun":
                this.activeTitle = "安迅";
                this.textContentData = anxunTextContent();
                break;
            case "dfyx":
                this.textContentData = dfyxTextContent();
                break;
            case "light":
                this.textContentData = lightTextContent();
                break;
            case "gongfu":
            case "jiuzai":
                this.textContentData = window[`${this.currentProjectEnv}TextContent`]();
                break;
            default:
                this.textContentData = bncTextContent();
                break;
        }
        // 项目化控制
        let currentProjectEnv = this.currentProjectEnv;
        // currentProjectEnv = "light";
        switch (currentProjectEnv) {
            case "bnc":
            case "dfyx":
                // 暂时隐藏
                // $(".footTop, .q_concent").css({
                //     display: "block",
                // });
                break;
            default:
                break;
        }
    },
    methods: {
        changeTitle(item, index) {
            function shuffle(arr) {
                arr.sort(() => Math.random() - 0.5);
            }
            this.activeTitle = item;
            if (this.currentProjectEnv !== "light") {
                let datas = this.textContentData.talkData.datas;
                shuffle(datas);
                this.textContentData.talkData.datas = datas;
            } else {
                this.textContentData.talkData.datas = this.textContentData.talkData.othertalkData[index];
            }
        },
        findMore() {
            let datas = this.textContentData.talkData.datas;
            let data = datas.slice(0, 5);
            let dataa = datas.concat(data);
            this.textContentData.talkData.datas = dataa;
        },
        changePage(title) {
            let cPicArr = this.textContentData.cPicArr;
            cPicArr = cPicArr.reverse();
            if (title === "left") {
                this.isleft = true;
            } else {
                this.isleft = false;

                this.textContentData.cPicArr = cPicArr;
            }
        },
        goIndustrial() {
            if (urls.currentProjectEnv == "light") {
                window.location.href = "../industrial-map-light/index.html";
            } else {
                window.location.href = "../industrial-map/index.html";
            }
        },
    },
    mounted() {
        bncBizUiFooter.initPageDom();
        bncMobileMgr.showAppDownloadQRCode("qrcode0", "qrcode1");
    },
});
